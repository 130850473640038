import * as React from 'react';
import Head from 'next/head';

import { SITE_TITLE } from '@src/config/constants';

export interface ErrorPageProps {}

/**
 * @name ErrorPage
 * @description Simple 404 page
 */
export const ErrorPage = (_props: ErrorPageProps) => {
  return (
    <div>
      <Head>
        <title>Not found | {SITE_TITLE}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="ui-container-sm pb-8x">
        <div className="my-8x">
          <img alt="Page not found!" src="/images/svg/page-not-found.svg" />
        </div>
        <div className="my-8x">
          <p>
            We couldn’t find the page you were looking for. This is either
            because:
          </p>
          <ul>
            <li>
              There is an error in the URL entered into your web browser. Please
              check the URL and try again.
            </li>
            <li>The page you are looking for has been moved or deleted.</li>
          </ul>
          <p>
            You can return to the previous page by clicking browser’s back
            button.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
